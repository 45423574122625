import React from "react";
import Slider from "react-infinite-logo-slider";
import slide1 from "../images/media/logo/1.png";
import slide2 from "../images/media/logo/2.png";
import slide3 from "../images/media/logo/4.png";
import slide4 from "../images/media/logo/6.png";
import slide5 from "../images/media/logo/5.png";
import slide6 from "../images/media/logo/7.png";
import slide7 from "../images/media/logo/3.png";

function MediaSlideBar() {
  return (
    <div className="flex items-center w-full bg-[#29ABE226] py-5 mt-8">
      <Slider
        id="talent1"
        width="270px"
        duration={55}
        blurBorders={false}
        blurBoderColor={"#fff"}
        toRight={false}
      >
        <Slider.Slide>
          <div className="max-w-[140px] w-full m-auto">
            <img className="w-full h-full" src={slide1} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[90px] w-full m-auto">
            <img className="w-full h-full" src={slide2} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[100px] w-full m-auto">
            <img className="w-full h-full" src={slide3} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[160px] w-full m-auto">
            <img className="w-full h-full" src={slide4} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[180px] w-full m-auto">
            <img className="w-full h-full" src={slide5} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[165px] w-full m-auto">
            <img className="w-full h-full" src={slide6} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[240px] w-full m-auto">
            <img className="w-full h-full" src={slide7} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[140px] w-full m-auto">
            <img className="w-full h-full" src={slide1} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[90px] w-full m-auto">
            <img className="w-full h-full" src={slide2} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[100px] w-full m-auto">
            <img className="w-full h-full" src={slide3} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[160px] w-full m-auto">
            <img className="w-full h-full" src={slide4} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[180px] w-full m-auto">
            <img className="w-full h-full" src={slide5} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[165px] w-full m-auto">
            <img className="w-full h-full" src={slide6} alt="slide" />
          </div>
        </Slider.Slide>
        <Slider.Slide>
          <div className="max-w-[240px] w-full m-auto">
            <img className="w-full h-full" src={slide7} alt="slide" />
          </div>
        </Slider.Slide>
      </Slider>
    </div>
  );
}

export default MediaSlideBar;
