import React from 'react'
import logo from '../images/bp-logo.png'
import x from '../images/x.svg'
import telegram from '../images/telegram.svg'
import { Link } from 'react-router-dom'
import { useLanguage } from "../Helpers/LanguageContext";

function Footer() {
    const { languageData } = useLanguage();
    return (
        <footer className='w-full py-4 bg-[#161C24]'>
            <div className='max-w-[1255px] m-auto w-full px-2 md:flex items-center justify-between'>
                <img className='w-[55px] md:w-[78px] m-auto md:m-0' src={logo} alt="logo" />
                <div className='flex items-center justify-center gap-6 mt-4 md:hidden'>
                    <img className='w-[32px] cursor-pointer' src={x} alt="x" />
                    <img className='w-[32px] cursor-pointer' src={telegram} alt="telegram" />
                </div>
                <div className='md:hidden'>
                    <div className='flex justify-center gap-4 items-center mt-4 md:mt-0'>
                        <Link to="https://blinkplanet.com" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink1}</p></Link>
                        <Link to="https://studio.blinkplanet.com/" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>Studio</p></Link>
                        <Link to="https://players.bpexplorer.io/" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>Players</p></Link>
                        <Link to="https://partners.bpexplorer.io/traffic" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink2}</p></Link>
                        <Link to="https://partners.bpexplorer.io/utility" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>Utility</p></Link>
                    </div>
                    <div className='flex justify-center gap-4 items-center mt-1 md:mt-0'>
                        <Link to="https://partners.bpexplorer.io/collections" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink3}</p></Link>
                        <Link to="https://partners.bpexplorer.io/press" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink4}</p></Link>
                        <Link to="#" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink5}</p></Link>

                    </div>
                    <div className='flex justify-center gap-4 items-center mt-1 md:mt-0'>

                        <Link to="#" target='_blank'><p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink6}</p></Link>
                    </div>
                </div>
                <div className='md:flex items-center gap-10 hidden'>
                    <div className='flex justify-center gap-4 items-center mt-4 md:mt-0'>

                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="https://blinkplanet.com" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink1}</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>
                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="https://studio.blinkplanet.com/" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>Studio</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>
                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="https://players.bpexplorer.io/" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>Players</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>
                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="https://partners.bpexplorer.io/traffic" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink2}</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>
                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="https://partners.bpexplorer.io/utility" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>Utility</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>
                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="https://partners.bpexplorer.io/collections" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink3}</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>
                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="https://partners.bpexplorer.io/press" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink4}</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>

                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="#" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink5}</p>
                            <hr className='h-4 w-[1px] border-none bg-[#C4CDD5]' />
                        </Link>
                        <Link className='flex justify-center gap-4 items-center mt-4 md:mt-0' to="#" target='_blank'>
                            <p className='text-[11px] text-[#C4CDD5] cursor-pointer hover:underline max-w-[110px] w-full md:w-[unset] text-center'>{languageData?.footerLink6}</p>
                        </Link>
                    </div>
                    <p className='text-xs text-center text-[#C4CDD5] mt-4 md:mt-0 hidden md:block'>BlinkPlanet © 2024.</p>
                    <div className='items-center justify-center gap-2 hidden md:flex'>
                        <Link to="https://x.com/joinblinkplanet" target='_blank'>
                            <img className='w-[32px] cursor-pointer' src={x} alt="x" />
                        </Link>
                        <Link to="https://t.me/explorer_quarters" target='_blank'>
                            <img className='w-[32px] cursor-pointer' src={telegram} alt="telegram" />
                        </Link>
                    </div>
                </div>
                <p className='text-xs text-center text-[#C4CDD5] mt-4 md:mt-0 md:hidden'>BlinkPlanet © 2024.</p>
            </div>
        </footer>
    )
}

export default Footer
