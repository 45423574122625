import { BrowserRouter, Route, Routes } from "react-router-dom";
import Players from "./pages/Players";
import { LanguageProvider } from "./Helpers/LanguageContext";
import TosPDF from "./pages/TosPDF";
import PrivacyPDF from "./pages/PrivacyPDF";
import MainToSPDF from "./pages/MainToSPDF";
import mixpanel from "mixpanel-browser";

function App() {
  mixpanel.init("bc70b8ef1bbe8618038ac48a2f1962b3", {
    debug: true,
  });
  return (
    <div className="App">
      <LanguageProvider>
        <BrowserRouter>
        
          <Routes>

            <Route path="/" element={<Players />} />
            <Route path="/launchcampaign/tos" element={<TosPDF />} />
            <Route path="/launchcampaign/privacy" element={<PrivacyPDF />} />
            <Route path="/launchcampaign/bp-tos" element={<MainToSPDF />} />
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </div>
  );
}

export default App;
