import React, { createContext, useState, useContext, useEffect } from 'react';
import API from './API.js';
import langs from './langs.json'
/*
import en from './lang/en.json';
import es from './lang/es.json';
import pt from './lang/pt.json';
*/


const LanguageContext = createContext();


export const LanguageProvider = ({ children }) => {
    const params = new URLSearchParams(window.location.search);
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    const persona = params.get('p');
    const [languageData, setLanguageData] = useState({});

    useEffect(() => {

        if (localStorage.getItem('language') === null) {
            const country = API.getUserCountry();
            country
                .then((result) => {
                    console.log(result);
                    let userLang;
                    switch (result) {
                        case 'EN':
                            userLang = 'en';
                            setLanguageData(langs.en || langs.en['default']);
                            break;
                        case 'BR':
                        case 'PT':
                            userLang = 'pt';
                            setLanguageData(langs.pt || langs.pt['default']);
                            break;
                        default:
                            userLang = 'en';
                            setLanguageData(langs.en || langs.en['default']);
                            break;
                    }

                    setLanguage(userLang);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setLanguage(localStorage.getItem('language'));
            if (localStorage.getItem('language') === 'en') {
                setLanguageData(langs.en || langs.en['default']);
            } else if (localStorage.getItem('language') === 'pt') {
                setLanguageData(langs.pt || langs.pt['default']);
            } else {
                setLanguageData(langs.en || langs.en['default']);
            }
        }

    }, [language]);

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, languageData }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};