import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '../components/lib/icon';
import logoBp from '../images/bp-logo.png';
import logoExp from '../images/exp-logo.png';
import { Link } from 'react-router-dom';
import { slide as BurgerMenu } from 'react-burger-menu';
import { useLanguage } from "../Helpers/LanguageContext";

function Header() {
    const { languageData } = useLanguage();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <header className='w-full fixed top-0 bg-[#1B1C1E] z-50'>
                <div className='flex justify-between items-center h-[47px] lg:h-[100px] max-w-[1255px] w-full m-auto px-4'>
                    <div className='flex justify-center items-center gap-1 lg:gap-4 cursor-pointer'>
                        <Icon
                            className='mr-1 lg:w-[40px] lg:h-[40px]'
                            icon="icon-menu"
                            fillColor="white"
                            onClick={toggleMenu}
                        />

                        <img onClick={handleScrollToTop} className='w-[44px] lg:w-[81px]' src={logoBp} alt="logoBp" />
                        <img onClick={handleScrollToTop} className='w-[83px] lg:w-[180px]' src={logoExp} alt="logoExp" />
                    </div>
                    <div className='flex items-center gap-4'>
                        <Link to='https://t.me/bpexplorer_bot' target='_blank'>
                            <button className="defaultBtnGradient text-white text-xs lg:text-[20px] font-bold px-2 lg:px-4 py-2 lg:py-4 hover:scale-[0.98] duration-100 rounded-lg sora">
                                {languageData?.headerBtn}
                            </button>
                        </Link>
                    </div>
                </div>
            </header>
            <div ref={menuRef}>
                <BurgerMenu
                    left
                    isOpen={isMenuOpen}
                    onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
                    customBurgerIcon={false}
                    customCrossIcon={false}
                >
                    <div className='w-full overflow-hidden lg:pt-12'>
                        <Link to='https://www.blinkplanet.com/' target='_blank' onClick={e => setIsMenuOpen(false)}>
                            <div className="w-full hover:bg-[#D6D6D61A] pt-1 lg:mt-8 duration-200 cursor-pointer group">
                                <p className="text-base lg:text-[31px] sora opacity-60 group-hover:opacity-100 text-center font-bold text-white my-4">Blink Planet</p>
                                <div className="w-full h-[1px] duration-200  lineGradient"></div>
                            </div>
                        </Link>
                        <Link to='https://studio.blinkplanet.com/' target='_blank' onClick={e => setIsMenuOpen(false)}>
                            <div className="w-full hover:bg-[#D6D6D61A] pt-1 lg:mt-8 duration-200 cursor-pointer group">
                                <p className="text-base lg:text-[31px] sora opacity-60 group-hover:opacity-100 text-center font-bold text-white my-4">Studio</p>
                                <div className="w-full h-[1px] duration-200  lineGradient"></div>
                            </div>
                        </Link>
                        <Link to='https://players.bpexplorer.io/' target='_blank' onClick={e => setIsMenuOpen(false)}>
                            <div className="w-full hover:bg-[#D6D6D61A] pt-1 lg:mt-8 duration-200 cursor-pointer group">
                                <p className="text-base lg:text-[31px] sora opacity-60 group-hover:opacity-100 text-center font-bold text-white my-4">Players</p>
                                <div className="w-full h-[1px] duration-200  lineGradient"></div>
                            </div>
                        </Link>
                        <Link to='https://partners.bpexplorer.io/traffic' target='_blank' onClick={e => setIsMenuOpen(false)}>
                            <div className="w-full hover:bg-[#D6D6D61A] pt-1 lg:mt-8 duration-200 cursor-pointer group">
                                <p className="text-base lg:text-[31px] sora opacity-60 group-hover:opacity-100 text-center font-bold text-white my-4">Traffic</p>
                                <div className="w-full h-[1px] duration-200  lineGradient"></div>
                            </div>
                        </Link>
                        <Link to='https://partners.bpexplorer.io/utility' target='_blank' onClick={e => setIsMenuOpen(false)}>
                            <div className="w-full hover:bg-[#D6D6D61A] pt-1 lg:mt-8 duration-200 cursor-pointer group">
                                <p className="text-base lg:text-[31px] sora opacity-60 group-hover:opacity-100 text-center font-bold text-white my-4">Utility</p>
                                <div className="w-full h-[1px] duration-200  lineGradient"></div>
                            </div>
                        </Link>
                        <Link to='https://partners.bpexplorer.io/collections' target='_blank' onClick={e => setIsMenuOpen(false)}>
                            <div className="w-full hover:bg-[#D6D6D61A] pt-1 lg:mt-8 duration-200 cursor-pointer group">
                                <p className="text-base lg:text-[31px] sora opacity-60 group-hover:opacity-100 text-center font-bold text-white my-4">Collections</p>
                                <div className="w-full h-[1px] duration-200  lineGradient"></div>
                            </div>
                        </Link>
                        <Link to='https://partners.bpexplorer.io/press' target='_blank' onClick={e => setIsMenuOpen(false)}>
                            <div className="w-full hover:bg-[#D6D6D61A] pt-1 lg:mt-8 duration-200 cursor-pointer group">
                                <p className="text-base lg:text-[31px] sora opacity-60 group-hover:opacity-100 text-center font-bold text-white my-4">Press</p>
                                <div className="w-full h-[1px] duration-200  lineGradient"></div>
                            </div>
                        </Link>
                    </div>
                </BurgerMenu>
            </div>
        </>
    );
}

export default Header;
