import React from 'react'

function NavigateBlock({ title, subtitle, image }) {
    return (
        <div className='max-w-[274px] lg:max-w-[390px] w-full m-auto lg:m-0'>
            <div className='bg-navigateTitleBg bg-cover w-[217px] lg:w-[320px] h-[66px] lg:h-[98px] m-auto flex justify-center items-center mt-6'>
                <p className='text-white text-xl lg:text-2xl font-bold space text-center'>{title}</p>
            </div>
            <div className='bg-navigateBlockBg bg-cover w-full h-[317px] lg:h-[480px] flex justify-center items-center mt-4'>
                <div>
                    <p className='text-white text-xs lg:text-sm text-center leading-[18px] max-w-[222px] lg:max-w-[338px] w-full m-auto'>{subtitle}</p>
                    <img className='max-w-[150px] lg:max-w-[250px] m-auto mt-3 lg:mt-10' src={image} alt="navigate" />
                </div>
            </div>
        </div>
    )
}

export default NavigateBlock
