import React, { useState, useEffect } from 'react';
import blinker1 from '../images/blinkers/1.png';
import blinker2 from '../images/blinkers/2.png';
import blinker3 from '../images/blinkers/3.png';
import blinker4 from '../images/blinkers/4.png';
import blinker5 from '../images/blinkers/5.png';
import blinker6 from '../images/blinkers/6.png';
import or from '../images/bg-blocks/gearUpOr.png';
import { cn } from '../utills/cn';
import { useLanguage } from "../Helpers/LanguageContext";

function GearUp() {
    const { languageData } = useLanguage();
    const blinkers = [
        {
            image: blinker1,
            name: languageData?.gearBlinker1Name,
            desc: languageData?.gearBlinker1Desk
        },
        {
            image: blinker2,
            name: languageData?.gearBlinker2Name,
            desc: languageData?.gearBlinker2Desk
        },
        {
            image: blinker3,
            name: languageData?.gearBlinker3Name,
            desc: languageData?.gearBlinker3Desk
        },
        {
            image: blinker4,
            name: languageData?.gearBlinker4Name,
            desc: languageData?.gearBlinker4Desk
        },
        {
            image: blinker5,
            name: languageData?.gearBlinker5Name,
            desc: languageData?.gearBlinker5Desk
        },
        {
            image: blinker6,
            name: languageData?.gearBlinker6Name,
            desc: languageData?.gearBlinker6Desk
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    const handleNext = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % blinkers.length);
            setFade(true);
        }, 200);
    };

    const handleBack = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + blinkers.length) % blinkers.length);
            setFade(true);
        }, 200);
    };

    const handleSelect = (index) => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex(index);
            setFade(true);
        }, 200);
    };

    return (
        <>
            <div className='mt-6 lg:flex justify-between max-w-[1280px] px-1 w-full m-auto'>
                <div className='bg-gearUpToogle bg-cover max-w-[564px] w-full h-[607px] hidden lg:flex items-center justify-center'>
                    <ul className='grid gap-8 mt-8'>
                        {blinkers.map((blinker, index) => (
                            <li
                                key={index}
                                className={cn('text-[28px] text-white cursor-pointer flex items-center gap-3', {
                                    'toggleTitleShadow': currentIndex === index,
                                })}
                                onClick={() => handleSelect(index)}
                            >
                                <span className={cn('w-2 h-2 bg-white rounded-full', {
                                    'toggleDotShadow': currentIndex === index,
                                })}></span>
                                {blinker.name}
                            </li>
                        ))}
                    </ul>
                </div>
                <img className='w-[111px] h-[125px] mt-[190px] mr-[-20px] hidden lg:flex' src={or} alt="or" />
                <div className='max-w-[329px] lg:max-w-[607px] w-full m-auto lg:mr-[6px]'>
                    <div className='bg-gearUpSliderMobile lg:bg-gearUpSliderDesk bg-cover w-full h-[210px] lg:h-[447px] flex items-center justify-center'>
                        <img
                            className={cn('h-[140px] lg:h-[210px] object-contain mt-10 transition-opacity duration-300', {
                                'opacity-100': fade,
                                'opacity-0': !fade
                            })}
                            src={blinkers[currentIndex].image}
                            alt="blinker"
                        />
                    </div>
                    <div className='bg-gearUpSubtitleMobile lg:bg-gearUpSubtitleDesk bg-cover flex items-center justify-center w-full lg:max-w-[524px] h-[105px] lg:h-[155px] mt-2 lg:mt-6 lg:m-auto'>
                        <p className={cn('max-w-[297px] lg:max-w-[492px] w-full text-white text-xs lg:text-base transition-opacity duration-300 lg:leading-[22px]', {
                            'opacity-100': fade,
                            'opacity-0': !fade
                        })}>
                            {blinkers[currentIndex].desc}
                        </p>
                    </div>
                </div>
                <div className='w-full flex items-center mt-2 lg:hidden max-w-[329px] m-auto'>
                    <div className="shadow-container mr-[-10px]">
                        <button
                            className='bg-clipPathPrevBtn w-[75px] h-[40px] bg-cover flex items-center justify-center text-white text-xs font-bold uppercase space'
                            onClick={handleBack}
                        >
                            {languageData?.gearBtnPrev}
                        </button>
                    </div>
                    <div className='w-full bg-[#29ABE233] h-[27px] flex items-center justify-center'>
                        <p className='text-white text-base'>{blinkers[currentIndex].name}</p>
                    </div>
                    <div className="shadow-container ml-[-10px]">
                        <button
                            className='bg-clipPathNextBtn w-[75px] h-[40px] bg-cover flex items-center justify-center text-white text-xs font-bold uppercase space'
                            onClick={handleNext}
                        >
                            {languageData?.gearBtnNext}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GearUp;
