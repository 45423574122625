import React, { useEffect } from "react";
import Header from "../components/Header";
import homeHero from "../images/home-hero.png";
import NavigateBlock from "../components/NavigateBlock";
import navigate1 from "../images/navigate/1.png";
import navigate2 from "../images/navigate/2.png";
import navigate3 from "../images/navigate/3.png";
import GearUp from "../components/GearUp";
import Launch from "../components/Launch";
import Feture from "../components/Feture";
import blinker from "../images/blink-space.png";
import intro from "../images/intro-hero.png";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useLanguage } from "../Helpers/LanguageContext";
import mixpanel from "mixpanel-browser";
import Media from "../components/Media";
import MediaSlideBar from "../components/MediaSlideBar";
function Players() {
  const { languageData, language } = useLanguage();

  useEffect(() => {
    mixpanel.track("Page View", {
      page_name: "Players",
    });
  }, []);

  return (
    <div className="bg-starBg bg-center overflow-hidden">
      <section className="py-10 md:py-20 md:pt-28">
        <Header />
        <div className="max-w-[1255px] w-full m-auto px-4 relative">
          <div className="w-full max-w-[327px] md:max-w-[700px] m-auto lg:ml-0 lg:mt-[105px]">
            <div className="bg-homeBgMobile md:bg-homeBgDesk duration-100 bg-cover bg-center w-full h-[271px] flex items-center mt-6">
              <div className="max-w-[270px] md:max-w-[660px] m-auto">
                <p className="text-white space text-2xl md:text-[36px] text-center font-bold uppercase leading-8 md:leading-[50px]">
                  {languageData?.hero_title}
                </p>
                <p className="text-white text-xs md:text-xl text-center leading-[18px] mt-4">
                  {languageData?.heroSubtitle}
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <div className="shadow-container">
                <Link to="https://t.me/bpexplorer_bot" target="_blank">
                  <button className="defaultBtnGradient clipPath text-white text-xs md:text-[20px] font-bold px-4 md:px-10 py-1 md:py-4 uppercase hover:scale-[0.98] duration-100 rounded space">
                    {languageData?.heroButton}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <img
            className="absolute right-[-70px] top-[-170px] z-[-1] w-[637px] hidden lg:block"
            src={homeHero}
            alt="homeHero"
          />
        </div>
      </section>
      <section className="px-2 py-2 md:py-20">
        <p className="flex items-center gap-2 md:gap-4 space text-white font-bold text-2xl md:text-[64px] max-w-[1255px] m-auto md:px-4">
          <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
          {languageData?.expTitle}
        </p>
        <div className="md:flex items-center justify-between max-w-[1255px] m-auto mt-4 gap-2">
          <NavigateBlock
            title={languageData?.expTab1Title}
            subtitle={languageData?.expTab1SubTitle}
            image={navigate1}
          />
          <NavigateBlock
            title={languageData?.expTab2Title}
            subtitle={languageData?.expTab2SubTitle}
            image={navigate2}
          />
          <NavigateBlock
            title={languageData?.expTab3Title}
            subtitle={languageData?.expTab3SubTitle}
            image={navigate3}
          />
        </div>
      </section>
      <section className="px-2 py-10 md:py-20">
        <p className="flex items-center gap-2 md:gap-4 space text-white font-bold text-2xl md:text-[64px] max-w-[1255px] m-auto md:px-4 md:leading-[60px]">
          <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
          {languageData?.gearTitle}
        </p>
        <GearUp />
      </section>
      <section className="px-2 py-5 md:py-20">
        <p className="flex items-center gap-2 md:gap-4 space text-white font-bold text-2xl md:text-[64px] max-w-[1255px] m-auto md:px-4 md:leading-[60px]">
          <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
          {languageData?.launchTitle}
        </p>
        <Launch />
      </section>

      <section className=" py-5 md:py-20 relative">
        <div className="max-w-[1255px] m-auto md:px-4">
          <p className="flex items-center gap-2 md:gap-4 space text-white font-bold text-2xl md:text-[64px] md:leading-[60px]">
            <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
            {languageData?.fetureTitle}
          </p>
          <p className="text-white text-xs md:text-xl leading-4 my-4 md:my-12">
            {languageData?.fetureSubTitle}
          </p>
        </div>
        <Feture />
        <img
          className="absolute left-[-20px] top-[824px] hidden lg:block pl-4 w-[345px]"
          src={blinker}
          alt="arrow2"
        />
        <section className=" pt-5 md:pt-10 relative bg-[#1D264A33] mt-10">
          <div className="w-full max-w-[1255px] m-auto">
            <div className="flex px-2 items-center gap-2 md:gap-4 sora text-white font-bold text-2xl lg:text-[64px] max-w-[1255px] m-auto lg:leading-[80px]">
              <div className="shape w-[5px] h-[37px] md:w-[9px] md:h-[65px]"></div>
              What are the media <br /> talking about us
            </div>
            <Media />
          </div>
          <MediaSlideBar />
        </section>
        <div className="px-2">
          <div className="bg-aboutMobile md:bg-aboutDesk duration-100 bg-cover bg-center max-w-[330px] md:max-w-[700px] lg:max-w-[1233px] m-auto w-full h-[231px] md:h-[275px] lg:h-[490px] flex md:items-start mt-6 pt-4 md:p-10 relative lg:mt-24">
            <div className="max-w-[270px] md:max-w-[unset] m-auto md:m-[unset] lg:pl-6 relative z-10">
              <p className="text-white text-xl md:text-3xl lg:text-[48px] font-bold leading-6 md:leading-[50px] lg:mt-6">
                {languageData?.aboutTitle}
              </p>
              <p className="text-white text-xs md:text-base lg:text-[28px] leading-[18px] lg:leading-10 mt-2 lg:max-w-[700px] lg:mt-6">
                {languageData?.aboutSubTitle}
              </p>
              <div className="shadow-container mt-4">
                <Link to="https://t.me/bpexplorer_bot" target="_blank">
                  <button className="defaultBtnGradient clipPath text-white text-xs md:text-[24px] font-bold px-4 md:px-12 py-2 md:py-4 uppercase hover:scale-[0.98] duration-100 rounded space">
                    {languageData?.aboutBtn}
                  </button>
                </Link>
              </div>
            </div>
            <img
              className="hidden lg:block absolute bottom-14 right-14 w-[400px]"
              src={intro}
              alt="intro"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Players;
