import React, { useState } from 'react'
import level1 from '../images/level/1.png'
import level2 from '../images/level/2.png'
import level3 from '../images/level/3.png'
import level4 from '../images/level/4.png'
import level5 from '../images/level/5.png'
import levelLine from '../images/bg-blocks/levelLine.svg'
import { cn } from '../utills/cn';
import { useLanguage } from "../Helpers/LanguageContext";

function Launch() {
    const { languageData } = useLanguage();
    const levels = [
        { title: languageData?.launch1Title, image: level1, level: languageData?.launch1Level, color: '#875F52' },
        { title: languageData?.launch2Title, image: level2, level: languageData?.launch2Level, color: '#E3A79E' },
        { title: languageData?.launch3Title, image: level3, level: languageData?.launch3Level, color: '#B5D9F2' },
        { title: languageData?.launch4Title, image: level4, level: languageData?.launch4Level, color: '#FFF173' },
        { title: languageData?.launch5Title, image: level5, level: languageData?.launch5Level, color: '#516FE4' },
    ]

    const [currentLevelIndex, setCurrentLevelIndex] = useState(0)
    const [fade, setFade] = useState(true)

    const handleNext = () => {
        setFade(false)
        setTimeout(() => {
            setCurrentLevelIndex((prevIndex) =>
                prevIndex < levels.length - 1 ? prevIndex + 1 : 0
            )
            setFade(true)
        }, 200)
    }

    const handleBack = () => {
        setFade(false)
        setTimeout(() => {
            setCurrentLevelIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : levels.length - 1
            )
            setFade(true)
        }, 200)
    }

    const currentLevel = levels[currentLevelIndex]

    return (
        <div className='flex items-center justify-between max-w-[330px] md:max-w-[1000px] m-auto mt-4 md:mt-12'>
            <div className="shadow-container">
                <button
                    className='bg-clipPathPrevBtn w-[75px] md:w-[155px] h-[40px] md:h-[86px] bg-cover flex items-center justify-center text-white text-xs md:text-2xl font-bold uppercase space'
                    onClick={handleBack}
                >
                    {languageData?.gearBtnPrev}
                </button>
            </div>
            <div className='bg-launchMobile bg-cover bg-center max-w-[176px] md:max-w-[417px] w-full h-[172px] md:h-[410px] flex justify-center items-center'>
                <div
                    className={cn('transition-opacity duration-300', {
                        'opacity-100': fade,
                        'opacity-0': !fade,
                    })}
                >
                    <p className='text-white text-xs md:text-xl text-center'>{currentLevel.title}</p>
                    <img className='max-w-[134px] md:min-w-[276px] md:h-1 w-full mt-2.5 md:my-4' src={levelLine} alt="levelLine" />
                    <img className='mt-2.5 w-[100px] md:w-[250px] h-[60px] md:h-[150px] object-contain m-auto' src={currentLevel.image} alt={currentLevel.level} />
                    <p
                        className='text-xl md:text-[32px] text-center font-bold space mt-2 md:mt-4'
                        style={{ color: currentLevel.color }}
                    >
                        {currentLevel.level}
                    </p>
                </div>
            </div>
            <div className="shadow-container">
                <button
                    className='bg-clipPathNextBtn w-[75px] md:w-[155px] h-[40px] md:h-[86px] bg-cover flex items-center justify-center text-white text-xs md:text-2xl font-bold uppercase space'
                    onClick={handleNext}
                >
                    {languageData?.gearBtnNext}
                </button>
            </div>
        </div>
    )
}

export default Launch
