import React, { useRef } from "react";
import { useLanguage } from "../Helpers/LanguageContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import block1 from "../images/media/1.png";
import block2 from "../images/media/2.png";
import block3 from "../images/media/3.png";
import block4 from "../images/media/4.png";
import block5 from "../images/media/5.png";
import block6 from "../images/media/6.png";
import logo1 from "../images/media/logo/1.png";
import logo2 from "../images/media/logo/2.png";
import logo3 from "../images/media/logo/3.png";
import logo4 from "../images/media/logo/4.png";
import logo5 from "../images/media/logo/5.png";
import logo6 from "../images/media/logo/6.png";
import logo7 from "../images/media/logo/7.png";
import { Link } from "react-router-dom";

function Media() {
  const { languageData } = useLanguage();
  const swiperRef = useRef(null);

  const slides = [
    {
      image: block1,
      text: "Oferecendo compra de ativos digitais com cartão de crédito, essa plataforma quer democratizar Web3",
      sourceLogo: logo1,
      link: "https://exame.com/bussola/oferecendo-compra-de-ativos-digitais-com-cartao-de-credito-essa-plataforma-quer-democratizar-web3/",
    },
    {
      image: block2,
      text: "Democratização do acesso ao mercado de ativos digitais",
      sourceLogo: logo2,
      link: "https://observatoriodegames.uol.com.br/mercado/giro-tech-confira-os-destaques-da-semana-2",
    },
    {
      image: block3,
      text: "Blink Planet dá até R$ 250 em cartões pré-pagos para a Black Friday",
      sourceLogo: logo3,
      link: "https://marcasemercados.com.br/blink-planet-da-ate-r-250-em-cartoes-pre-pagos-para-a-black-friday/",
    },
    {
      image: block4,
      text: "Evento gratuito em BH aborda o futuro das finanças descentralizadas",
      sourceLogo: logo4,
      link: "https://livecoins.com.br/evento-gratuito-em-bh-aborda-o-futuro-das-financas-descentralizadas/",
    },
    {
      image: block5,
      text: "Evento gratuito em Belo Horizonte sobre DeFi tem prêmio de US$ 100",
      sourceLogo: logo5,
      link: "https://br.beincrypto.com/bom-dia-cripto-pix-tem-novas-regras-de-seguranca-a-partir-de-hoje/",
    },
    {
      image: block6,
      text: "O mercado de criptomoedas no Brasil está com diversas novidades nesta semana decisiva de novembro.",
      sourceLogo: logo6,
      link: "https://br.cointelegraph.com/news/crypto-news-god-bless-bitcoin-film-users-see-cannabis-validators-various-crypto-events-and-other-news",
    },
    {
      image: block1,
      text: "Blink Planet Quer Democratizar o Acesso aos Ativos Digitais com Plataforma Simplificada",
      sourceLogo: logo7,
      link: "https://bitcoinblock.com.br/2024/11/06/blink-planet-democratizacao-ativos-digitais/",
    },
  ];

  return (
    <div className="flex items-center justify-between m-auto mt-4 md:mt-12 ml-3 md:ml-0">
      <div className="shadow-container hidden lg:block">
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          className="bg-clipPathPrevBtn w-[75px] md:w-[175px] h-[40px] md:h-[66px] bg-cover flex items-center justify-center text-white text-xs md:text-2xl font-bold uppercase space"
        >
          Back
        </button>
      </div>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        spaceBetween={15}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 1.2,
          },
          640: {
            slidesPerView: 1,
          },
        }}
        className="w-full max-w-[370px] md:max-w-[650px]"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="mediaBlockShadow m-[3px] md:flex bg-[#29ABE226] items-center h-[385px] md:h-[240px]">
              <img
                className="md:max-w-[300px] object-cover h-[175px] md:h-full w-full"
                src={slide.image}
                alt="block"
              />
              <div className="p-2 md:p-4 max-w-[350px] w-full m-auto">
                <p className="sora font-bold leading-6 text-lg md:text-xl text-white text-center md:text-left h-[100px] md:h-full items-center flex">
                  {slide.text}
                </p>
                <p className="text-white sora text-sm flex items-center justify-center md:justify-start gap-1 md:gap-2 my-4">
                  Fonte{" "}
                  <img
                    className="h-[15px] md:h-[20px]"
                    src={slide.sourceLogo}
                    alt="logo"
                  />
                </p>
                <div className="flex justify-center md:justify-start">
                  <Link to={slide.link} target="_blank">
                    <button className="bg-[#E85621] rounded text-white font-bold sora text-base p-2">
                    Leia mais
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="shadow-container hidden lg:block">
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className="bg-clipPathNextBtn w-[75px] md:w-[175px] h-[40px] md:h-[66px] bg-cover flex items-center justify-center text-white text-xs md:text-2xl font-bold uppercase space"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Media;
