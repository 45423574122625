//
// WARNING
//
// Do not make manual changes to this file.
// This file was generated by generate-icons.js.
//
//


/**
 * A list of all available icons in the icon set.
 */
export const availableIcons = ['icon-menu', 'icon-telegram'];

export const IconSvg = {
  'icon-menu': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25"><path fill='currentColor' d="M4 18.5a.97.97 0 0 1-.712-.288A.97.97 0 0 1 3 17.5q0-.424.288-.712A.97.97 0 0 1 4 16.5h16q.425 0 .713.288T21 17.5q0 .424-.288.713A.96.96 0 0 1 20 18.5zm0-5a.97.97 0 0 1-.712-.288A.97.97 0 0 1 3 12.5q0-.424.288-.712A.97.97 0 0 1 4 11.5h16q.425 0 .713.288T21 12.5q0 .424-.288.713A.96.96 0 0 1 20 13.5zm0-5a.97.97 0 0 1-.712-.288A.97.97 0 0 1 3 7.5q0-.424.288-.712A.97.97 0 0 1 4 6.5h16q.425 0 .713.288.288.289.287.712 0 .424-.288.713A.96.96 0 0 1 20 8.5z"/></svg>,
  'icon-telegram': <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none" viewBox="0 0 33 32"><g clipPath="url(#a)"><path fill='currentColor' fillRule="evenodd" d="M21.638 9.338a1.125 1.125 0 0 1 1.546 1.22l-1.7 10.317c-.166.996-1.258 1.566-2.17 1.07a44 44 0 0 1-2.918-1.72c-.51-.334-2.072-1.402-1.88-2.163.165-.65 2.79-3.094 4.29-4.546.588-.571.32-.9-.375-.375-1.727 1.303-4.499 3.285-5.415 3.843-.809.492-1.23.576-1.734.492-.92-.153-1.773-.39-2.469-.678-.94-.39-.894-1.683 0-2.06z" clipRule="evenodd"/><path stroke='currentColor' d="M.682 16c0-8.56 6.94-15.5 15.5-15.5s15.5 6.94 15.5 15.5-6.94 15.5-15.5 15.5S.682 24.56.682 16Z"/></g><defs><clipPath id="a"><path fill='currentColor' d="M33 0H0v32h33z"/></clipPath></defs></svg>,
};
