import React, { useEffect } from 'react';
import pdf from '../assets/pdf/terms_of_use.pdf';

export default function MainToSPDF() {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = pdf;
        link.download = 'tos.pdf';
        
        link.click();
    }, []);

    return <div></div>;
}