import { MouseEventHandler, cloneElement } from 'react';
import React from 'react';
import { IconDefinition, IconSvg } from './icon-generated';

export function Icon(props) {
    if (!props.icon || !IconSvg[props.icon]) {
        return null;
    }

    const icon = cloneElement(IconSvg[props.icon], {
        style: { ...props.style },
        className: props.className,
        onClick: props.onClick,
        ...(props.alt
            ? {
                children: [
                    <title key="title">{props.alt}</title>,
                    ...updateFillColor(IconSvg[props.icon].props.children, props.fillColor || 'currentColor'),
                ],
            }
            : {
                children: updateFillColor(IconSvg[props.icon].props.children, props.fillColor || 'currentColor'),
            }),
    });


    return icon;
}

const updateFillColor = (children, fillColor) => {
    return React.Children.map(children, child => {
        if (child.type === 'path') {
            return React.cloneElement(child, {
                fill: fillColor,
            });
        } else if (child.type === 'defs' || child.type === 'clipPath') {
            return React.cloneElement(child, {
                children: updateFillColor(child.props.children, fillColor || 'currentColor'),
            });
        } else if (child.props && child.props.children) {
            return React.cloneElement(child, {
                children: updateFillColor(child.props.children, fillColor || 'currentColor'),
            });
        }
        return child;
    });
};