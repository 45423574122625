import React from 'react'
import hero from '../images/feture-hero.png'
import eye from '../images/3step-eye.png'
import arrow1 from '../images/arrow1step.svg'
import arrow2 from '../images/arrow2step.svg'
import { useLanguage } from "../Helpers/LanguageContext";

function Feture() {
    const { languageData } = useLanguage();
    return (
        <div className='max-w-[1255px] m-auto w-full px-2 relative'>
            <img className='max-w-[330px] w-full m-auto md:hidden' src={hero} alt="hero" />
            <div className='bg-feture1_2Mobile md:bg-feture1Desk bg-cover bg-center max-w-[330px] md:max-w-[710px] w-full h-[250px] md:h-[412px] m-auto md:ml-0 mt-4 flex justify-center items-center relative'>
                <div className='flex justify-center items-center gap-10'>
                    <p className='text-center md:text-start text-xl font-bold text-white md:text-[#29ABE2] space toggleTitleShadow2 md:text-5xl leading-6 w-[50%] md:absolute md:left-8 md:top-2'> {languageData?.feture1Title} <br className='md:hidden' /> {languageData?.feture1TitleSpan}</p>
                    <img className='max-w-[270px] w-full m-auto hidden md:block' src={hero} alt="hero" />
                    <ul className='list-disc max-w-[200px] md:max-w-[330px] w-full grid gap-3'>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture1option1}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture1option2}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture1option3}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture1option4}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture1option5}</li>
                    </ul>
                </div>
            </div>
            <img className='absolute left-1/2 transform -translate-x-1/2 top-1 pl-8 hidden lg:block' src={arrow1} alt="arrow1" />
            <div className='bg-feture1_2Mobile md:my-14 md:mb-12 md:bg-feture2Desk bg-cover bg-center max-w-[330px] md:max-w-[710px] w-full h-[250px] md:h-[412px] m-auto md:mr-0 mt-4 flex justify-center items-center relative'>
                <div className='flex justify-center items-center gap-10 md:mt-[70px]'>
                    <p className='text-center md:text-start text-xl font-bold text-white md:text-[#29ABE2] space toggleTitleShadow2 md:text-5xl leading-6 w-[50%] md:absolute md:left-8 md:top-10'>{languageData?.feture2Title} <br className='md:hidden' /> {languageData?.feture2TitleSpan}</p>
                    <img className='max-w-[270px] w-full m-auto hidden md:block' src={hero} alt="hero" />
                    <ul className='list-disc max-w-[200px]  md:max-w-[330px] w-full grid gap-3'>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture2option1}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture2option2}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture2option3}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture2option4}</li>
                        <li className='text-white text-xs md:text-lg leading-[18px]'>{languageData?.feture2option5}</li>
                    </ul>
                </div>
            </div>
            <img className='absolute right-3 top-[854px] hidden lg:block pl-4' src={arrow2} alt="arrow2" />
            <div className='bg-feture3Mobile md:bg-feture3Desk bg-cover bg-center max-w-[330px] md:max-w-[710px] w-full h-[250px] md:h-[412px] m-auto md:ml-0 mt-4 flex justify-center items-center relative'>
                <div className='flex justify-center items-center gap-10 md:mt-4'>
                    <p className='text-center md:text-start text-xl font-bold text-white md:text-[#29ABE2] space toggleTitleShadow2 md:text-5xl leading-6 w-[50%] md:absolute md:left-8 md:top-4'>{languageData?.feture3Title} <br className='md:hidden' /> {languageData?.feture1TitleSpan}</p>
                    <div className='max-w-[200px] md:max-w-[unset] w-full'>
                        <p className='text-[#29ABE2] space font-bold text-xs md:text-[32px] uppercase text-center'>{languageData?.feture3Subtitle1}</p>
                        <img className='w-[24px] md:w-[50px] m-auto my-2 md:my-4' src={eye} alt="eye" />
                        <p className='text-[#29ABE2] space font-bold text-xs md:text-[32px] uppercase text-center'>{languageData?.feture3Subtitle2}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feture
