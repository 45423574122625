import React, { useEffect } from 'react';
import pdf from '../assets/pdf/privacy_policy.pdf';

export default function PrivacyPDF() {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = pdf;
        link.download = 'privacy_policy.pdf';
        
        link.click();
    }, []);

    return <div></div>;
}